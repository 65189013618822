// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** ResourceRenderer */
const ResourceRenderer = ({
  resource: { image },
  tabIndex,
  style,
  loading,
}) => (
  <GatsbyImage
    image={getImage(image)}
    backgroundColor="var(--alt-background-color)"
    objectFit="contain"
    style={{
      maxHeight: '62vh',
    }}
    imgStyle={{
      maxHeight: '62vh',
    }}
    loading={loading}
  />
)

// ----------------------------------------------------------------------------
// ----------------------------------------------------------- Compose & Export
// ----------------------------------------------------------------------------
export default ResourceRenderer
