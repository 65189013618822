// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import startCase from 'lodash/startCase'
import map from 'lodash/map'
import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'
import min from 'lodash/min'
import max from 'lodash/max'
import kebabCase from 'lodash/kebabCase'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import Division from '@bodhi-project/components/lib/division'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/4.10.3/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/4.10.3/col/style/css'

import Tooltip from 'antd/lib/tooltip'
import '@bodhi-project/antrd/lib/4.10.3/tooltip/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import Slider from './slider'

import seoHelper from '../../methods/seoHelper'

import './style.less'

import {
  pushObjectIntoShoppingCart as pushObjectIntoShoppingCartFX,
  pushNotice as pushNoticeFX,
} from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const pageData = {
  pageTitle: 'LiGHT FiSH Store',
  nakedPageSlug: '/store',
  pageAbstract:
    'Focused on the minimalistic beauty of natural materials. Sustainable materials are carefully chosen and crafted into visionary and intelligently designed products. Much like the stages of growth of a plant, from seed to tree, LiGHT-FiSH chooses a design process that is organic and systematic.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query BookPageQuery($slug: String) {
    allResources(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          subTitle
          abstract
          materials
          cableLength
          sizes
          weights
          lightBulbs
          slug
          hasTags
          fromCollection
          images {
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          colorOptions {
            code
            color
          }
          variants {
            title
            code
            material
            weight
            cordLength
            dimensions
            retailPrice
            url
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** ProductPage */
const ProductPage = (props) => {
  const { data, pushNotice, pushObjectIntoShoppingCart } = props

  const {
    allResources: {
      edges: [{ node }],
    },
  } = data

  const {
    title,
    subTitle,
    abstract,
    materials,
    cableLength,
    lightBulbs,
    fromCollection,
    slug,
    images,
    variants,
  } = node

  const numberOfVariants = variants.length
  const prices = map(variants, 'retailPrice')
  const minPrice = min(prices)
  const maxPrice = max(prices)

  const categories = {
    theAcaciaCollection:
      'A series of ceiling, wall and hanging task lamps, produced from wood grown in Auroville. The Acacia hardwood is harvested only from dead, fallen trees. Long lasting and of high quality, these lamps are a veritable product of Auroville, and create a very personal, warm and intimate atmosphere.',
    metalArtCollection:
      'A sleek interior décor designed to delight, our Metal Art is crafted with great attention to details and interlocks without any screws, glue or weld marks. Optimal 2D designs and laser-cutting allow for minimal waste during production. Stainless Steel, Mild Steel, Aluminium and Acrylic are used in this collection.',
    grappaCollection:
      'The Grappa Collection offers sparkling, colorful, subdued and elegant solo or cluster lightings. The colour palette allows one to create very personal chandeliers.',
    glowCollection:
      'Inspired by naturally occurring patterns, lights in the Glow Collection create modern-chic ambiences. Laser-cut from acrylic sheets with a choice of design patterns, they are ideal for indoors but can be used outdoors under roof protection. Crafted with great attention to details they interlock without any screws or glue.',
  }

  return (
    <ConfigProvider prefixCls="wtf">
      <StandardPageWrapper
        className="product-page"
        seoData={seoData}
        {...props}
      >
        <Division Row={Row} Col={Col} goldenAlt>
          <Slider resources={images} />
          <div>
            <h1>{title}</h1>
            <h2 style={{ opacity: 0.7 }}>
              <small>{subTitle}</small>
            </h2>
            <p>
              <big>{abstract}</big>
            </p>
            {/* <p>
              <big>
                From the {startCase(fromCollection)} collection.{' '}
                {categories[fromCollection]}
              </big>
            </p>
            <br />
            <p>
              {numberOfVariants === 1 ? 'Variant' : 'Variants'}:{' '}
              {numberOfVariants}{' '}
            </p>
            <p>
              Retail Price:{' '}
              {minPrice === maxPrice ? (
                <Fragment>₹{minPrice}</Fragment>
              ) : (
                <Fragment>
                  ₹{minPrice}–{maxPrice}
                </Fragment>
              )}
            </p>
            {isUndefined(materials) === false && <p>Materials: {materials}</p>}
            {isUndefined(cableLength) === false && (
              <p>Cable Length: {cableLength}</p>
            )}
            {isUndefined(lightBulbs) === false && <p>Bulb: {lightBulbs}</p>}
            <br /> */}
            <h2 style={{ opacity: 0.7 }}>
              <small>Variants</small>
            </h2>
            <div className="variants">
              {map(
                variants,
                ({
                  title: variantTitle,
                  material: variantMaterial,
                  code,
                  weight,
                  dimensions,
                  retailPrice,
                  url,
                }) => (
                  <div className="variant">
                    <div style={{ display: 'flex' }}>
                      <h3 style={{ flex: 1 }}>
                        <small>{variantTitle}</small>
                      </h3>
                      <h4
                        className="as-h3"
                        style={{ flex: 1, textAlign: 'right' }}
                      >
                        <small>₹{retailPrice}</small>
                      </h4>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <p
                        style={{ marginBottom: 0, textAlign: 'left', flex: 1 }}
                      >
                        Product Code: {code}
                        <br />
                        {isNull(variantMaterial) === false && (
                          <Fragment>
                            Material: {variantMaterial}
                            <br />
                          </Fragment>
                        )}
                        Weight: {weight}
                        <br />
                        Dimensions: {dimensions}
                      </p>
                      <p
                        style={{ marginBottom: 0, textAlign: 'right', flex: 1 }}
                      >
                        <button
                          className="shop-now"
                          title="Buy on LiGHT-FiSH Web Shop"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault()
                            pushObjectIntoShoppingCart({
                              title: variantTitle,
                              price: retailPrice,
                              routeSlug: `${slug}#${kebabCase(code)}`,
                            })
                            pushNotice({
                              message: 'Added',
                              description: (
                                <p style={{ marginBottom: 0 }}>
                                  Added {variantTitle} to the shopping cart.
                                </p>
                              ),
                            })
                          }}
                        >
                          Buy on LiGHT-FiSH Web Shop
                        </button>
                        {isNull(url) === false && (
                          <Fragment>
                            <br />
                            <Link to={url}>Buy on auroville.com</Link>
                          </Fragment>
                        )}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </Division>
      </StandardPageWrapper>
    </ConfigProvider>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  }),
  (dispatch) => ({
    pushNotice(payload) {
      dispatch(pushNoticeFX(payload))
    },
    pushObjectIntoShoppingCart(payload) {
      dispatch(pushObjectIntoShoppingCartFX(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedProductPage = compose(
  withState // Add state
)(ProductPage)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedProductPage
