// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import ArrowLeft from '@bodhi-project/components/lib/svg/arrow-left'
import ArrowRight from '@bodhi-project/components/lib/svg/arrow-right'

import Carousel from 'antd/lib/carousel'
import '@bodhi-project/antrd/lib/4.10.3/carousel/style/css'

import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'
import smallKey from '@bodhi-project/components/lib/methods/smallKey'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import ResourceRenderer from './resource-renderer'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** PhotoSlider */
class PhotoSlider extends React.PureComponent {
  /** standard constructor */
  constructor(props) {
    super(props)
  }

  /** standard renderer */
  render() {
    const { resources } = this.props

    const sliderConf = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: false,
      vertical: true,
      customPaging: (i) => (
        <a>
          <GatsbyImage image={getImage(resources[i].image)} />
        </a>
      ),
      arrows: true,
      prevArrow: <ArrowLeft />,
      nextArrow: <ArrowRight />,
    }

    return (
      <div className="slider presentation as-paragraph">
        <Carousel {...sliderConf}>
          {resources.map((resource, index) => (
            <ResourceRenderer
              key={smallKey()}
              resource={resource}
              loading={index === 0 ? 'eager' : 'lazy'}
            />
          ))}
        </Carousel>
      </div>
    )
  }
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default PhotoSlider
